import React from 'react'
import Layout from '../components-en/layout'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Head from '../components-en/head'
import Img from "gatsby-image"
import '../styles/grid.scss'
import '../styles/index.scss'
import blogHero from "../images/blog_hero.svg"

const BlogPage = () => {
 
  const blogData = useStaticQuery(graphql`
  query{
    allMarkdownRemark {
      edges {
        node {
          frontmatter{
            title
            date
            hashtag
            summary
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields{
            slug
          }
        }
      }
    }
  }          
`)



    return(
    <Layout>
      <Head title="Blog" description="Birikimle ilgili kaynaklar, Çember ahalisinin kurduğu hayaller ve hikayeler burada." />
      <div className="row blog-index">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Money, money, money <span role="img" aria-label="money">💸</span></h1>
                <p>Birikimle ilgili kaynaklar, Çember ahalisinin kurduğu hayaller ve hikayeler burada. Senin de bizimle paylaşmak istediğin bir hikayen varsa bize ulaşabilirsin.</p>
                <h5><Link to="/contact" className="cta-blue-button">Bize Yaz</Link></h5>
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={blogHero} alt="Blog"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

        <div className="row featured-blog">
        <div className="container">
          <div className="row">
            
              {blogData.allMarkdownRemark.edges.map((edge)=>{
                let featuredImgFluid = edge.node.frontmatter.featuredImage.childImageSharp.fluid
                return(
                  <div className="col-4">
                    <Link to={`/blog/${edge.node.fields.slug}`}>
                      <Img fluid={featuredImgFluid} />
                      <h2>{edge.node.frontmatter.title}</h2>
                      <p>{edge.node.frontmatter.summary}</p>
                      <span><b>{edge.node.frontmatter.hashtag}</b></span>
                      <span>{edge.node.frontmatter.date}</span>
                    </Link>
                  </div>
                
                )
              })}
            
          </div>
        </div>
      </div>
      
     
      
    </Layout>
  )
}

export default BlogPage